
import { Options, Vue } from "vue-class-component";
import DarkToggle from "@/components/global/DarkToggle.vue";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import PageHeading from "@/components/utils/PageHeading.vue";

@Options({
  components: {
    DarkToggle,
    HeaderShadow,
    PageHeading,
  },
})
export default class Settings extends Vue {}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white dark:bg-nucleus-darkerBlue p-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_PageHeading = _resolveComponent("PageHeading")!
  const _component_DarkToggle = _resolveComponent("DarkToggle")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_PageHeading, { heading: "Settings" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DarkToggle)
    ])
  ]))
}